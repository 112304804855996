import {
  ApplicationConfig,
  importProvidersFrom,
  inject,
  provideAppInitializer,
  provideExperimentalZonelessChangeDetection,
} from '@angular/core';
import { provideRouter, RouteReuseStrategy, withComponentInputBinding } from '@angular/router';
import { routes } from './app-routes';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { TokenInterceptor } from './module/shared/interceptor/token.interceptor';
import { HttpEventInterceptor } from './module/shared/interceptor/http-event.interceptor';
import { AuthErrorService } from './module/shared/service/auth-error.service';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ApiModule as KidflApiModule } from '@kidfl/openapi';
import { environment } from '../environments/environment';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom([
      BrowserModule,
      IonicModule.forRoot({
        useSetInputAPI: true,
      }),
      FormsModule,
      ReactiveFormsModule,
      KidflApiModule.forRoot({
        rootUrl: environment.apiUrl,
      }),
      MonacoEditorModule.forRoot(),
    ]),
    provideExperimentalZonelessChangeDetection(),
    provideRouter(routes, {
      ...withComponentInputBinding(),
    }),
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpEventInterceptor,
      multi: true,
    },
    provideAppInitializer(() => {
      const initializerFn = (
        (authErrorService: AuthErrorService) => () =>
          authErrorService.init()
      )(inject(AuthErrorService));
      return initializerFn();
    }),
    provideHttpClient(withInterceptorsFromDi()),
  ],
};
