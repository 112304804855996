import { Routes } from '@angular/router';
import { AuthorizedGuard } from './module/shared/guard/authorized.guard';

export const routes: Routes = [
  {
    path: 'order',
    loadChildren: () => import('./module/order/order-routes').then((r) => r.ORDER_ROUTES),
    canActivate: [AuthorizedGuard],
  },
  {
    path: 'customer',
    loadChildren: () => import('./module/customer/customer.routes').then((r) => r.CUSTOMER_ROUTES),
    canActivate: [AuthorizedGuard],
  },
  {
    path: 'category',
    loadChildren: () => import('./module/category/category.routes').then((r) => r.CATEGORY_ROUTES),
    canActivate: [AuthorizedGuard],
  },
  {
    path: 'camp',
    loadChildren: () => import('./module/camp/camp.routes').then((r) => r.CAMP_ROUTES),
    canActivate: [AuthorizedGuard],
  },
  {
    path: 'gallery',
    loadChildren: () => import('./module/gallery/gallery-routes').then((m) => m.GALLERY_ROUTES),
    canActivate: [AuthorizedGuard],
  },
  {
    path: 'trips',
    loadChildren: () =>
      import('./module/camp-trip/camp-trip-routes').then((m) => m.CAMP_TRIP_ROUTES),
    canActivate: [AuthorizedGuard],
  },
  {
    path: 'locations',
    loadChildren: () =>
      import('./module/camp-trip-location/camp-trip-location-routes').then(
        (m) => m.CAMP_TRIP_LOCATION_ROUTES,
      ),
    canActivate: [AuthorizedGuard],
  },
  {
    path: 'image',
    loadChildren: () => import('./module/image/image-routes').then((m) => m.IMAGE_ROUTES),
    canActivate: [AuthorizedGuard],
  },
  {
    path: 'discount',
    loadChildren: () => import('./module/discount/discount-routes').then((m) => m.DISCOUNT_ROUTES),
    canActivate: [AuthorizedGuard],
  },
  {
    path: 'blog',
    loadChildren: () => import('./module/blog/blog-routes').then((m) => m.BLOG_ROUTES),
    canActivate: [AuthorizedGuard],
  },
  { path: '', redirectTo: 'order', pathMatch: 'full' },
  {
    path: '',
    loadChildren: () => import('./module/user/user-routes').then((r) => r.USER_ROUTES),
  },
];
